import React, { ComponentProps } from "react"
import { Link } from "@reach/router"

import { CustomButtonProps, useButtonClassName } from "./Button"

type ButtonStyleLinkProps = ComponentProps<typeof Link> &
	CustomButtonProps & {
		disabled?: boolean
	}
const ButtonStyleLink: React.FC<ButtonStyleLinkProps> = ({
	className,
	size,
	appearance,
	straight,
	children,
	...linkProps
}) => {
	const completeClassName = useButtonClassName(
		className,
		size,
		appearance,
		straight
	)

	return (
		<Link className={completeClassName} {...linkProps}>
			{children}
		</Link>
	)
}

export default ButtonStyleLink
