import React, { PropsWithChildren } from "react"

import classNames from "../../helpers/classNames"

import Styles from "./Card.module.css"

export const enum CardSizeEnum {
	TINY,
	SMALL,
	MEDIUM,
	NORMAL,
}

type CardProps = {
	size?: CardSizeEnum
	className?: string
}
const Card = React.forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
	({ children, size, className }, ref) => {
		return (
			<div
				ref={ref}
				className={classNames({
					[Styles.card]: true,
					[Styles.tiny]: size === CardSizeEnum.TINY,
					[Styles.small]: size === CardSizeEnum.SMALL,
					[Styles.medium]: size === CardSizeEnum.MEDIUM,
					[className || ""]: !!className,
				})}
			>
				{children}
			</div>
		)
	}
)

export default Card
