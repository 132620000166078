import { offsetLimitPagination } from "helpers/offsetLimitPagination"

import { AllTypesMap, User } from "./generated/graphql-types"
import {
	readDateTime,
	readDateTimeArray,
	readDateTimeUTC,
	readOptionalDateTime,
} from "./helpers/readDateTime"
import { StrictTypePolicies } from "./helpers/types"

export const typePolicies: StrictTypePolicies<AllTypesMap> = {
	Subscription: {},
	PorpaginasResult_Sale: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_NormalClassification: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_User: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Shipment: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Quota: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Star: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Ratingdata: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Device: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Pin: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Article: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_ArticleClassification: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Devicetype: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_ClassificationCode: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_DeviceCountClassification: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_CalculatedClassification: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_RankedClassification: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_RankingLock: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Champion: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_ClassificationCustomType: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_Event: {
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_EventRegistration: {
		keyFields: false,
		fields: {
			items: offsetLimitPagination(),
		},
	},
	PorpaginasResult_DevicesSale: {
		keyFields: false,
		fields: {
			items: offsetLimitPagination(),
		},
	},
	ClassificationInterface: {
		fields: {
			users: {
				merge(existing: User[], incoming: User[]) {
					return incoming
				},
			},
			archived: readOptionalDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Address: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Article: {
		fields: {
			published: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	ArticleClassification: {
		fields: {
			readDate: readDateTime,
			archived: readOptionalDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	CalculatedClassification: {
		keyFields: false,
	},
	CompareClassification: {
		keyFields: false,
		fields: {
			dates: readDateTimeArray,
		},
	},
	CalculationEntry: {
		keyFields: false,
	},
	CalculationEntryInterface: {
		keyFields: false,
	},
	Classification: {
		fields: {
			archived: readOptionalDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	ClassificationMetadata: {
		fields: {
			entry_date: readOptionalDateTime,
			birthday: readOptionalDateTime,
			created: readDateTime,
			modified: readDateTime,
		},
	},
	ClassificationCode: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	ClassificationsPin: {
		fields: {
			date: readDateTime,
			created: readDateTime,
			modified: readDateTime,
		},
	},
	ClassificationsPinsRatingdata: {},
	ContactInfo: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	DatedCalculationEntry: {
		keyFields: false,
		fields: {
			date: readDateTimeUTC,
		},
	},
	IntervalCalculationEntry: {
		keyFields: false,
	},
	DateDiff: {
		keyFields: false,
		fields: {
			start: readDateTime,
			end: readDateTime,
		},
	},
	DatedEntryData: {
		fields: {
			date: readDateTimeUTC,
		},
	},
	DatedInt: {
		keyFields: false,
		fields: {
			date: readDateTimeUTC,
		},
	},
	DatedValue: {
		keyFields: false,
		fields: {
			date: readDateTimeUTC,
		},
	},
	DatedValueInterface: {
		keyFields: false,
		fields: {
			date: readDateTimeUTC,
		},
	},
	DestructuredSerial: {
		keyFields: false,
	},
	Device: {
		fields: {
			production: readOptionalDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	DeviceCountClassification: {
		keyFields: false,
		fields: {
			archived: readOptionalDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	DeviceSerialInfo: {
		keyFields: false,
	},
	Devicetype: {
		fields: {
			ranking_start: readOptionalDateTime,
			ranking_end: readOptionalDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	DevicesSale: {
		fields: {
			change_date: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	EntryData: {
		keyFields: false,
	},
	File: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	GeneratedFile: {},
	Mutation: {},
	NormalClassification: {
		fields: {
			archived: readOptionalDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Pin: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Pinrule: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Point: {
		fields: {
			date: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	PorpaginasResult_Point: {},
	Quota: {
		fields: {
			date: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	RankedClassification: {
		keyFields: false,
	},
	Ratingdata: {
		fields: {
			date: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Role: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Sale: {
		fields: {
			sold: readDateTime,
			lockedDate: readOptionalDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Shipment: {
		fields: {
			imported: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Star: {
		fields: {
			date: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	SummedClassification: {
		keyFields: false,
	},
	User: {
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
			lastActionTime: readOptionalDateTime,
		},
	},
	RankingLock: {
		fields: {
			locked_month: readDateTime,
			start: readDateTime,
		},
	},
	QueuedJob: {
		fields: {
			completed: readOptionalDateTime,
			fetched: readOptionalDateTime,
			notBefore: readOptionalDateTime,
			created: readDateTime,
		},
	},
	Champion: {
		fields: {
			date: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			archived: readOptionalDateTime,
			deleted: readOptionalDateTime,
		},
	},
	ImportDiffData: {
		keyFields: false,
	},
	ImportDiffChildren: {
		keyFields: ["id"],
	},
	SaleStruct: {
		keyFields: ["internalId", "importerId"],
		fields: {
			sold: readDateTime,
		},
	},
	ClassificationCustomType: {
		keyFields: ["id"],
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	Event: {
		keyFields: ["id"],
		fields: {
			start_date: readDateTime,
			end_date: readDateTime,
			notification_start: readOptionalDateTime,
			registration_until: readDateTime,
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	EventRegistration: {
		keyFields: ["id"],
		fields: {
			created: readDateTime,
			modified: readDateTime,
			deleted: readOptionalDateTime,
		},
	},
	StornoStruct: {
		fields: {
			date: readDateTime,
		},
	},
}
