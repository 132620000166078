import React, { useCallback, useState, useMemo } from "react"
import { RouteComponentProps } from "@reach/router"
import { SubmitHandler } from "@unform/core"
import * as Yup from "yup"
import { ReactComponent as Logo } from "assets/svg/logo.svg"
import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

import { useAuthenticationContext } from "../../context/AuthenticationContext"
import ValidatedForm, {
	SubmitFormButton,
} from "../../components/Form/ValidatedForm"
import Input from "../../components/Form/Input"
import Card from "../../components/Card/Card"
import ButtonStyleLink from "../../components/Buttons/ButtonStyleLink"

import Styles from "./Login.module.css"

export const LoginViewWrap: React.FC = ({ children }) => {
	const [t] = useTranslation("authentication")

	return (
		<div className={Styles.background}>
			<Logo
				aria-label={t("login.logoAriaLabel", "Hyla Logo")}
				className={Styles.logo}
			/>

			<div className={Styles.contentWrap}>
				<Card className={Styles.box}>{children}</Card>
			</div>
		</div>
	)
}

const GetStructureLoginSchema = (t: TFunction) =>
	Yup.object().shape({
		email: Yup.string().required(
			t("login.enterEmail", "Please enter your email address")
		),
		password: Yup.string().required(
			t("login.enterPassword", "Please enter your password")
		),
	})

type LoginData = Yup.InferType<ReturnType<typeof GetStructureLoginSchema>>

const Login: React.FC<RouteComponentProps> = () => {
	const [t] = useTranslation("authentication")

	const { authenticate, active } = useAuthenticationContext(true)

	const [success, setSuccess] = useState<boolean | undefined>(undefined)

	const onSubmit = useCallback<SubmitHandler<LoginData>>(
		async (data) => {
			setSuccess(undefined)

			try {
				await authenticate(data.email, data.password)
			} catch (e) {
				setSuccess(false)
				return
			}

			setSuccess(true)
		},
		[authenticate]
	)

	const LoginSchema = useMemo(() => GetStructureLoginSchema(t), [t])

	return (
		<LoginViewWrap>
			<ValidatedForm validation={LoginSchema} onSubmit={onSubmit}>
				<h1>{t("login.headline", "Hyla Center - Login")}</h1>

				{success === true && active !== false && (
					<p>
						{t(
							"login.successfulLogin",
							"Login successful. You are being redirected to the system."
						)}
					</p>
				)}
				{success === false && (
					<p>
						{t(
							"login.failedLogin",
							"Could not log in. Please check your email and password."
						)}
					</p>
				)}
				{active === false &&
					t(
						"login.accountLocked",
						"Your account is locked. Please contact your account manager."
					)}

				<Input
					label={t("login.email", "E-Mail")}
					name="email"
					placeholder="john.doe@hyla.com"
					autoComplete="username"
					type="text"
				/>
				<Input
					label={t("login.password", "Password")}
					type="password"
					name="password"
					placeholder="Enter your password here"
					autoComplete="current-password"
				/>

				<div className="mt-4 flex">
					<SubmitFormButton
						type="submit"
						appearance="primary"
						className="mr-auto"
					>
						{t("login.loginButton", "Login")}
					</SubmitFormButton>
					<ButtonStyleLink
						to="/forgot-password"
						appearance="default"
						className="ml-auto"
					>
						{t("login.forgotPassword", "I forgot my password!")}
					</ButtonStyleLink>
				</div>
			</ValidatedForm>
		</LoginViewWrap>
	)
}

export default Login
