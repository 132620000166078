import React, {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useState,
} from "react"
import uniqueId from "lodash/uniqueId"
import { IconLookup } from "@fortawesome/fontawesome-svg-core"

import Alerts, { AlertType } from "../components/Alerts/Alerts"

interface AlertInterface {
	type: AlertType
	icon?: IconLookup
	title: string
	content: ReactNode
	dismissable?: boolean
	onDismiss?: () => void
}

interface AlertContextInterface {
	addAlert: (alert: AlertInterface) => void
}
const AlertContext = createContext<AlertContextInterface | null>(null)

export const useAlertContext = () => {
	const ctx = useContext(AlertContext)

	if (ctx === null) {
		throw new Error("No AlertContextProvider available.")
	}

	return ctx
}

export interface AlertWithId extends AlertInterface {
	id: string
}

export const AlertContextProvider: React.FC = ({ children }) => {
	const [alerts, setAlerts] = useState<AlertWithId[]>([])

	const removeAlert = useCallback(
		(id: string) => () => {
			setAlerts((alerts) => alerts.filter((a) => a.id !== id))
		},
		[]
	)

	const addAlert = useCallback((alert: AlertInterface) => {
		setAlerts((alerts) => [
			...alerts,
			{
				id: uniqueId("alert-"),
				...alert,
			},
		])
	}, [])

	const ctx = {
		addAlert,
	}

	return (
		<AlertContext.Provider value={ctx}>
			<Alerts alerts={alerts} onDismiss={removeAlert} />
			{children}
		</AlertContext.Provider>
	)
}
