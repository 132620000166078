import React, { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { hash, bitArray } from "sjcl"

import { useAlertContext } from "../context/AlertContext"

import { AlertType } from "./Alerts/Alerts"
import Button from "./Buttons/Button"

const getCurrentIndexHash = async (cache: boolean = false) => {
	while (true) {
		try {
			const res = await fetch(`${window.location.origin}/index.html`, {
				cache: cache ? "default" : "no-cache",
			})

			return hash.sha256.hash(await res.text())
		} catch (error) {
			// check every 30s if network is availabe.
			// the outer loop will continue when network is accessible.

			// using do {} while () instead of while {}
			// to wait 30s before starting checking network every 30s.
			// this prevents fetching permanently if "only" the server is down.
			do {
				await new Promise((res) => setTimeout(res, 30000))
			} while (!window.navigator.onLine)
		}
	}
}

const UpdateAlert: React.FC = () => {
	const [t] = useTranslation()

	return (
		<Trans t={t} ns="general" i18nKey="updateAlert.updateAvailable">
			A new version of Hyla Center is available.
			<br />
			Only the newest version will continue to work.
			<br />
			<Button
				appearance="primary"
				onClick={() => window.location.reload()}
				className="mt-2"
			>
				Update Now
			</Button>
		</Trans>
	)
}

const CheckUpdates: React.FC = () => {
	const [t] = useTranslation("general")
	const { addAlert } = useAlertContext()
	const [localVersionHashPromise] = useState(getCurrentIndexHash(true))

	useEffect(() => {
		let mounted = true
		let interval: NodeJS.Timeout | undefined = undefined
		let alerted = false

		const checkUpdate = async () => {
			if (!mounted || alerted) {
				return
			}

			if (
				bitArray.equal(
					await localVersionHashPromise,
					await getCurrentIndexHash()
				)
			) {
				return
			}

			// check mounting-state again - the promises may have taken a while
			if (!mounted || alerted) {
				return
			}

			alerted = true

			// disable interval
			if (interval) {
				clearInterval(interval)
				interval = undefined
			}

			addAlert({
				title: t<string>("updateAlert.title", "New Version available"),
				content: <UpdateAlert />,
				type: AlertType.Info,
				dismissable: false,
			})
		}

		checkUpdate()
		// check index.html every 5 minutes
		interval = setInterval(checkUpdate, 300000)

		return () => {
			mounted = false
			if (interval !== undefined) {
				clearInterval(interval)
			}
		}
	}, [localVersionHashPromise, addAlert, t])

	return null
}

export default CheckUpdates
