import React, {
	createContext,
	MutableRefObject,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react"
import { navigate, globalHistory } from "@reach/router"

import { API_ENDPOINT } from "../declarations"
import { apolloClient } from "../apolloClient"

import { useMaintenanceContext } from "./MaintenanceContext"

interface AuthenticationContextInterface {
	authenticated: boolean | undefined | null
	active: boolean | undefined
	authenticate(username: string, password: string): Promise<void>
	logout(): Promise<void>
	validatePromise: MutableRefObject<Promise<void> | undefined>
}
const AuthenticationContext =
	createContext<AuthenticationContextInterface | null>(null)

export const useAuthenticationContext = (
	suspense = false
): Omit<AuthenticationContextInterface, "validatePromise"> => {
	const ctx = useContext(AuthenticationContext)

	if (ctx === null) {
		throw new Error("No AuthenticationContextProvider available.")
	}

	if (suspense) {
		const timedPromise = new Promise((res) => setTimeout(res, 500))

		const { current } = ctx.validatePromise
		if (current) {
			// wait until current promise is done, but at least 500ms
			// this makes the loading-states smoother
			throw Promise.all([current, timedPromise])
		}

		if (ctx.authenticated === null) {
			throw timedPromise
		}
	}

	return ctx
}

export const AuthenticationContextProvider: React.FC = ({ children }) => {
	const [authenticated, setAuthenticated] = useState<
		boolean | undefined | null
	>(null)
	const [active, setActive] = useState<boolean | undefined>(undefined)

	const { setMaintenance } = useMaintenanceContext()

	const validatePromise = useRef<Promise<void>>()

	const logout = useCallback(async () => {
		if (!authenticated) {
			return
		}

		await fetch(`${API_ENDPOINT}/users/logout`, {
			credentials: "include",
			headers: {
				accept: "application/json",
			},
		})

		await apolloClient.clearStore()

		setAuthenticated(undefined)
	}, [authenticated])

	const fetchAuthenticated = useCallback(async () => {
		const response = await fetch(`${API_ENDPOINT}/users/check`, {
			credentials: "include",
			headers: {
				accept: "application/json",
			},
		})

		if (response.status === 503) {
			const date = await response.text()
			setMaintenance(date.length > 0 ? date : true)
		}

		if (response.status !== 200) {
			throw new Error("could not fetch authenticated")
		}

		const { authenticated, active_classification } = await response.json()

		if (!authenticated) {
			throw new Error("could not fetch authenticated")
		}

		setAuthenticated(authenticated)
		setActive(active_classification)
	}, [setMaintenance])

	const authenticate = useCallback(
		async (username: string, password: string) => {
			const formData = new FormData()
			formData.set("email", username)
			formData.set("password", password)
			formData.set("remember_me", "on")

			const login = await fetch(`${API_ENDPOINT}/users/login`, {
				method: "post",
				body: formData,
				credentials: "include",
				headers: {
					accept: "application/json",
				},
			})

			const { success } = await login.json()

			if (!success) {
				throw new Error("username or password is wrong")
			}

			await fetchAuthenticated()
		},
		[fetchAuthenticated]
	)

	useEffect(() => {
		if (authenticated) {
			// trigger suspense until navigation is done
			;(async () => {
				const promise = (async () => {
					let path = globalHistory.location.pathname

					if (path === "/login") {
						path = "/"
					}

					if (active === undefined) {
						return
					}

					if (!active) {
						if (path === "login") {
							return
						}

						path = "/login"
					}

					await navigate(path || "/")
				})()

				validatePromise.current = promise

				await promise

				if (validatePromise.current === promise) {
					validatePromise.current = undefined
				}
			})()
			return
		}

		;(async () => {
			const promise = (async () => {
				try {
					await fetchAuthenticated()
				} catch (ignore) {
					setAuthenticated(undefined)
				}
			})()

			validatePromise.current = promise

			await promise

			if (validatePromise.current === promise) {
				validatePromise.current = undefined
			}
		})()
	}, [fetchAuthenticated, authenticated, active])

	const ctx = {
		active,
		authenticated,
		logout,
		authenticate,
		validatePromise,
	}

	return (
		<AuthenticationContext.Provider value={ctx}>
			{children}
		</AuthenticationContext.Provider>
	)
}
