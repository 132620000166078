import React, {
	ComponentProps,
	HTMLProps,
	useEffect,
	useMemo,
	useRef,
} from "react"
import { useField } from "@unform/core"
import { DateTime } from "luxon"

import Styles from "./Input.module.css"
import Validation from "./Validation"
import Label from "./Label/Label"
import Annotation from "./Annotation"

export const RenderInput = React.forwardRef<
	HTMLInputElement,
	HTMLProps<HTMLInputElement>
>(({ className, ...props }, ref) => {
	return (
		<input
			{...props}
			ref={ref}
			className={`${className || ""} ${Styles.input}`}
		/>
	)
})

const Input: React.FC<
	ComponentProps<typeof RenderInput> & {
		label: string
		name: string
		annotation?: string
		labelWhenOptional?: boolean
	}
> = ({
	label,
	name,
	type,
	annotation,
	required,
	labelWhenOptional,
	...props
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null)
	const fallbackRef = useRef(null)
	const previousDefaultVal = useRef<any>(null)
	const {
		fieldName,
		defaultValue = "",
		registerField,
		error,
	} = useField(name)

	useEffect(() => {
		registerField({
			name: fieldName,
			ref: inputRef.current,
			getValue(ref: HTMLInputElement) {
				switch (type) {
					case "number":
						return ref.valueAsNumber
					case "checkbox":
						return ref.checked
					case "date":
						if (ref.hasOwnProperty("valueAsDate")) {
							return ref.valueAsDate
						}

						return DateTime.fromFormat(ref.value, "yyyy-LL-dd", {
							zone: "utc",
						}).toJSDate()
					case "datetime-local":
						return ref.value
					case "file":
						if (!ref.files || !ref.files.length) {
							if (fallbackRef.current) {
								return fallbackRef.current
							}

							return undefined
						}

						if (ref.files?.length === 1) {
							return ref.files?.[0]
						} else {
							return ref.files
						}
					default:
						return ref.value
				}
			},
			setValue(ref: HTMLInputElement, value: any) {
				switch (type) {
					case "number":
						ref.value = value
						break
					case "checkbox":
						ref.checked = value
						break
					case "date":
						if (value instanceof Date) {
							ref.value = value.toISOString().replace("Z", "")
						} else {
							ref.value = value
						}
						break
					case "datetime-local":
						if (value instanceof Date) {
							ref.value = value.toISOString().replace("Z", "")
						} else {
							ref.value = value
						}
						break
					case "file":
						fallbackRef.current = value
						break
					default:
						ref.value = value
				}
			},
			clearValue(ref: HTMLInputElement) {
				ref.value = ""
			},
		})
	}, [fieldName, registerField, type])

	const formattedDefaultValue = useMemo(() => {
		if (type === "file") {
			return undefined
		}

		if (!(defaultValue instanceof Date)) {
			return defaultValue
		}

		const dateString = defaultValue.toISOString().replace("Z", "")
		return type === "datetime-local" ? dateString : dateString.split("T")[0]
	}, [type, defaultValue])

	// defaultValue does not automatically update value prop - this takes care of that
	useEffect(() => {
		if (type === "file") {
			return
		}

		if (previousDefaultVal.current === formattedDefaultValue) {
			return
		}

		if (
			inputRef.current &&
			inputRef.current.value === previousDefaultVal.current
		) {
			inputRef.current.value = formattedDefaultValue
		}

		previousDefaultVal.current = formattedDefaultValue
	}, [formattedDefaultValue, type])

	return (
		<Label
			label={label}
			isCheckbox={type === "checkbox"}
			isHidden={type === "hidden"}
			htmlFor={fieldName}
			required={required}
			labelWhenOptional={labelWhenOptional}
		>
			<RenderInput
				{...props}
				id={fieldName}
				defaultValue={
					props.value === undefined && type !== "checkbox"
						? formattedDefaultValue
						: undefined
				}
				defaultChecked={
					props.checked === undefined && type === "checkbox"
						? defaultValue
						: undefined
				}
				ref={inputRef}
				type={type}
			/>
			{annotation && <Annotation>{annotation}</Annotation>}
			{error && <Validation>{error}</Validation>}
		</Label>
	)
}

export default Input
