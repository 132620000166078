import React, {
	createContext,
	Reducer,
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useState,
} from "react"
import { DateTime } from "luxon"
import { faWrench } from "@fortawesome/pro-duotone-svg-icons"
import { Trans, useTranslation } from "react-i18next"

import MaintenanceNotification from "../components/MaintenanceNotification"
import { API_ENDPOINT } from "../declarations"
import { getIconLookup } from "../helpers/icons"
import { AlertType } from "../components/Alerts/Alerts"

import { useAlertContext } from "./AlertContext"

const getNextMaintenance = async (): Promise<DateTime | undefined> => {
	const response = await fetch(`${API_ENDPOINT}/users/nextMaintenance`, {
		credentials: "include",
	})

	if (response.status !== 200) {
		return undefined
	}

	const iso = await response.text()

	return DateTime.fromISO(iso).toLocal()
}

const dateReducer: Reducer<DateTime | undefined, DateTime | undefined> = (
	currentDate,
	newDate
) => {
	if (newDate && !currentDate?.equals(newDate)) {
		return newDate
	}

	return currentDate
}

const useCreateContext = () => {
	const { addAlert } = useAlertContext()

	const [t] = useTranslation("general")

	const [maintenance, _setMaintenance] = useState<boolean | DateTime>(false)
	const [nextMaintenance, _setNextMaintenance] = useReducer(
		dateReducer,
		undefined
	)

	const setMaintenance = useCallback((maintenance: boolean | string) => {
		if (typeof maintenance === "string") {
			_setMaintenance(DateTime.fromISO(maintenance))

			return
		}

		_setMaintenance(maintenance)
	}, [])

	useEffect(() => {
		const alertMaintenance = async () => {
			const date = await getNextMaintenance()

			_setNextMaintenance(date)
		}

		alertMaintenance().then()

		const interval = setInterval(alertMaintenance, 5 * 60 * 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		if (!nextMaintenance) {
			return
		}

		const date = nextMaintenance.toLocaleString(DateTime.DATETIME_FULL)

		addAlert({
			type: AlertType.Info,
			icon: getIconLookup(faWrench),
			title: t("maintenance.alert.title", "Planned Maintenance"),
			content: (
				<>
					<Trans t={t} i18nKey="maintenance.alert.content">
						System Maintenance will take place at{" "}
						<strong>{{ date }}</strong>.<br />
						Please complete your work until then to avoid data loss.
					</Trans>
				</>
			),
		})
	}, [nextMaintenance, addAlert, t])

	return {
		maintenance,
		setMaintenance,
	}
}
const MaintenanceContext = createContext<ReturnType<
	typeof useCreateContext
> | null>(null)

export const useMaintenanceContext = () => {
	const ctx = useContext(MaintenanceContext)

	if (ctx === null) {
		throw new Error("No MaintenanceContextProvider available.")
	}

	return ctx
}

export const MaintenanceContextProvider: React.FC = ({ children }) => {
	const ctx = useCreateContext()

	if (ctx.maintenance) {
		return (
			<>
				<MaintenanceNotification
					expectedDate={
						ctx.maintenance !== true ? ctx.maintenance : undefined
					}
				/>
			</>
		)
	}

	return (
		<MaintenanceContext.Provider value={ctx}>
			{children}
		</MaintenanceContext.Provider>
	)
}
