import {
	IconDefinition,
	IconLookup,
	library,
} from "@fortawesome/fontawesome-svg-core"

export const getIconLookup = (icon: IconDefinition): IconLookup => {
	library.add(icon)

	return {
		prefix: icon.prefix,
		iconName: icon.iconName,
	}
}
