import React from "react"
import { Alert as AlertComp } from "@reach/alert"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"

import { AlertWithId } from "../../context/AlertContext"
import Button from "../Buttons/Button"

import Styles from "./Alerts.module.css"

export enum AlertType {
	Info,
	Error,
}

const TypeToStyle = {
	[AlertType.Error]: Styles.error,
	[AlertType.Info]: Styles.info,
}

type AlertProps = {
	alert: AlertWithId
	onDismiss: () => void
}
const Alert = React.memo<AlertProps>(({ alert, onDismiss }) => {
	const onClose = () => {
		if (alert.onDismiss) {
			alert.onDismiss()
		}

		onDismiss()
	}
	return (
		<AlertComp
			type={alert.type === AlertType.Error ? "assertive" : "polite"}
			className={`${Styles.alert} ${TypeToStyle[alert.type] ?? ""}`}
		>
			<div className={Styles.headingRow}>
				<span className={Styles.heading}>{alert.title}</span>

				{alert.dismissable !== false && (
					<Button
						size="small"
						className={Styles.dismiss}
						onClick={onClose}
					>
						<FontAwesomeIcon size="xs" icon={faTimes} />
					</Button>
				)}
			</div>
			<span className={Styles.content}>{alert.content}</span>
		</AlertComp>
	)
})

type AlertsProps = {
	alerts: AlertWithId[]
	onDismiss: (id: string) => () => void
}
const Alerts: React.FC<AlertsProps> = ({ alerts, onDismiss }) => {
	return (
		<div className={Styles.alerts}>
			{alerts.map((alert) => {
				return (
					<Alert
						alert={alert}
						key={alert.id}
						onDismiss={onDismiss(alert.id)}
					/>
				)
			})}
		</div>
	)
}

export default Alerts
