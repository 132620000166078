import React from "react"

import Styles from "./Validation.module.css"

type ValidationProps = {}
const Validation: React.FC<ValidationProps> = ({ children }) => {
	return <span className={Styles.validation}>{children}</span>
}

export default Validation
