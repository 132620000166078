import { ApolloLink } from "@apollo/client/link/core/ApolloLink"
import { addBreadcrumb, Severity } from "@sentry/react"
import { Operation } from "@apollo/client"
import { OperationDefinitionNode } from "graphql"

const operationInfo = (operation: Operation) => {
	const type = operation.query.definitions.find(
		(defn) => defn.kind === "OperationDefinition"
	) as OperationDefinitionNode

	return {
		type: type.operation,
		name: operation.operationName,
		fragments: operation.query.definitions
			.map<string | undefined>((defn) =>
				defn.kind === "FragmentDefinition" ? defn.name.value : undefined
			)
			.filter(Boolean)
			.join(", "),
		variables: operation.variables,
	}
}

const sentryLink = new ApolloLink((operation, forward) => {
	if (process.env.NODE_ENV === "production") {
		addBreadcrumb({
			category: "graphql",
			data: operationInfo(operation),
			level: Severity.Debug,
		})
	} else if (
		process.env.NODE_ENV === "development" &&
		process.env.REACT_APP_SHOW_GRAPHQL_INFO
	) {
		console.table(operationInfo(operation))
	}

	return forward(operation)
})

export default sentryLink
