import { ApolloClient } from "@apollo/client/core/ApolloClient"
import { InMemoryCache } from "@apollo/client/cache/inmemory/inMemoryCache"
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries"
import { HttpLink } from "@apollo/client/link/http/HttpLink"
import { from, TypePolicies } from "@apollo/client"

import { readDateTimeUTC } from "./helpers/readDateTime"
import AbortableQueryLink from "./helpers/AbortableQueryLink"
import { typePolicies } from "./typePolicies"
import { apolloUsePreGeneratedHashes } from "./helpers/apolloUsePreGeneratedHashes"
import sentryLink from "./helpers/sentryLink"

const httpLink = new HttpLink({
	uri: `${process.env.REACT_APP_API_ENDPOINT}/__graphql`,
	credentials: "include",
})

const persistedLink = createPersistedQueryLink({
	generateHash: apolloUsePreGeneratedHashes(
		import("./generated/persisted-client.json").then(
			(query) => query.default
		)
	),
})

export const apolloClient = new ApolloClient({
	cache: new InMemoryCache({
		possibleTypes: {
			ClassificationInterface: [
				"NormalClassification",
				"ArticleClassification",
			],
		},
		typePolicies: {
			...(typePolicies as TypePolicies),
			Query: {
				fields: {
					firstDate: {
						read: readDateTimeUTC,
					},
					firstYear: {
						read: readDateTimeUTC,
					},
					nextDate: {
						read: readDateTimeUTC,
					},
					nextYear: {
						read: readDateTimeUTC,
					},
					currentDate: {
						read: readDateTimeUTC,
					},
					currentYear: {
						read: readDateTimeUTC,
					},
				},
			},
		},
	}),
	link: from([sentryLink, persistedLink, AbortableQueryLink, httpLink]),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: "cache-and-network",
		},
	},
	queryDeduplication: false,
})
