import React, { useMemo } from "react"

import classNames from "../../helpers/classNames"

import Style from "./Button.module.css"
import Appearances from "./ButtonAppearance.module.css"

type ButtonSizes = "normal" | "small" | "large"

export function useButtonClassName(
	className: string | undefined = "",
	size: ButtonSizes = "normal",
	appearance: keyof typeof Appearances = "default",
	straight: boolean = false
) {
	return useMemo(() => {
		return classNames({
			[`${Style.button} ${Style[size]} ${className}`]: true,
			[Appearances[appearance] || ""]: !!appearance,
			[Style.straight]: straight,
		})
	}, [className, size, appearance, straight])
}

export type CustomButtonProps = {
	appearance?: keyof typeof Appearances
	className?: string
	size?: ButtonSizes
	straight?: boolean
}

type ButtonProps = JSX.IntrinsicElements["button"] &
	CustomButtonProps & {
		type?: "button" | "submit" | "reset"
	}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	(
		{
			appearance = "default",
			className,
			type,
			children,
			size = "normal",
			straight = false,
			...rest
		},
		ref
	) => {
		const completeClassName = useButtonClassName(
			className,
			size,
			appearance,
			straight
		)
		return (
			<button
				type={type}
				className={completeClassName}
				ref={ref}
				{...rest}
			>
				{children}
			</button>
		)
	}
)

export default Button
