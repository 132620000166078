import React, { ComponentProps } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/pro-duotone-svg-icons"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

import CircularLoadingIndicator from "../LoadingIndicator/CircularLoadingIndicator"

import Button from "./Button"

const LoadingStateButton: React.FC<
	ComponentProps<typeof Button> & {
		icon?: IconProp
		loading?: boolean
		error?: boolean
	}
> = ({ loading, error, children, icon, ...props }) => {
	return (
		<Button disabled={loading} {...props} type={props.type ?? "button"}>
			<div className="flex bg-inherit">
				{(loading || error || icon) && (
					<div className="flex items-center justify-content my-auto mr-3 bg-inherit">
						{loading && (
							<CircularLoadingIndicator
								intermediate={loading}
								value={0}
								max={100}
							/>
						)}
						{!loading && error && (
							<FontAwesomeIcon icon={faTimesCircle} />
						)}
						{!loading && !error && icon && (
							<FontAwesomeIcon icon={icon} />
						)}
					</div>
				)}
				<span className="my-auto">{children}</span>
			</div>
		</Button>
	)
}

export default LoadingStateButton
