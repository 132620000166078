import React from "react"

import classNames from "../../../helpers/classNames"

import Styles from "./Label.module.css"

type LabelProps = JSX.IntrinsicElements["label"] & {
	isCheckbox?: boolean
	isHidden?: boolean
	label: React.ReactNode
	required?: boolean
	labelWhenOptional?: boolean
	small?: boolean
}
const Label: React.FC<LabelProps> = ({
	isCheckbox,
	isHidden,
	label,
	className,
	children,
	required,
	labelWhenOptional,
	small,
	...rest
}) => {
	return (
		<label
			className={classNames({
				[className || ""]: true,
				[Styles.label]: true,
				[Styles.checkBox]: !!isCheckbox,
				[Styles.hidden]: !!isHidden,
				[Styles.small]: !!small,
			})}
			{...rest}
		>
			<span className={Styles.labelText}>
				{label}
				{required && <span className={Styles.asterisk}>*</span>}
				{labelWhenOptional && !required && (
					<span className={Styles.optional}>(optional)</span>
				)}
			</span>
			{children}
		</label>
	)
}

export default Label
