import { ApolloLink, Observable } from "@apollo/client"
import { Subscription } from "zen-observable-ts"

const connections: Map<string, AbortController> = new Map()
const abortHandlers: Map<string, () => void> = new Map()
const handles: Map<string, Subscription> = new Map()

const AbortableQueryLink = new ApolloLink(
	(operation, forward) =>
		new Observable((observer) => {
			// Set x-CSRF token (not related to abort use case)
			const context = operation.getContext()
			/** Final touch to cleanup */

			const id: string | undefined = context.requestTrackerId

			const connectionHandle = forward(operation).subscribe({
				next: (...arg) => {
					observer.next(...arg)
				},
				error: (...arg) => {
					cleanUp(id)
					observer.error(...arg)
				},
				complete: (...arg) => {
					cleanUp(id)
					observer.complete(...arg)
				},
			})

			const cleanUp = (id: string | undefined) => {
				if (id) {
					handles.get(id)?.unsubscribe()
					connections.delete(id)
					handles.delete(id)
					abortHandlers.delete(id)
				}
			}

			if (id) {
				const controller = new AbortController()
				operation.setContext({
					...context,
					fetchOptions: {
						signal: controller.signal,
						...context?.fetchOptions,
					},
				})

				if (connections.has(id)) {
					// If a controller exists, that means this operation should be aborted.
					connections.get(id)?.abort()
					abortHandlers.get(id)?.()
					cleanUp(id)
				}

				connections.set(id, controller)
				handles.set(id, connectionHandle)

				if (typeof context.onRequestAbort === "function") {
					abortHandlers.set(id, context.onRequestAbort)
				}
			}

			return connectionHandle
		})
)

export default AbortableQueryLink
