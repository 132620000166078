import React, { Suspense } from "react"
import { Router } from "@reach/router"

import { GlobalErrorBoundary } from "./errors/GlobalErrorBoundary"
import { AuthenticationContextProvider } from "./context/AuthenticationContext"
import LoadingScreen from "./components/LoadingScreen/LoadingScreen"
import { AlertContextProvider } from "./context/AlertContext"
import { MaintenanceContextProvider } from "./context/MaintenanceContext"
import CheckUpdates from "./components/CheckUpdates"

const Login = React.lazy(
	() =>
		import(
			/*
		webpackChunkName: "pre-auth-login"
 	 */
			"./pages/Login/Login"
		)
)
const Welcome = React.lazy(
	() =>
		import(
			/*
		webpackChunkName: "pre-auth-welcome"
 	 */
			"./pages/Password/Welcome/Welcome"
		)
)
const ForgotPassword = React.lazy(
	() =>
		import(
			/*
			webpackChunkName: "pre-auth-forgot-password"
		 */
			"./pages/Password/ForgotPassword/ForgotPassword"
		)
)
const ResetPassword = React.lazy(
	() =>
		import(
			/*
			webpackChunkName: "pre-auth-reset-password"
		 */
			"./pages/Password/ResetPassword/ResetPassword"
		)
)
const Authenticated = React.lazy(
	() =>
		import(
			/*
		webpackChunkName: "authenticated"
	 */
			"./Authenticated"
		)
)

const App: React.FC = () => {
	return (
		<div>
			<AlertContextProvider>
				<GlobalErrorBoundary>
					<Suspense fallback={<LoadingScreen />}>
						<MaintenanceContextProvider>
							<AuthenticationContextProvider>
								<CheckUpdates />
								<Router>
									<Login path="/login" />
									<ForgotPassword path="/forgot-password" />
									<ResetPassword path="/reset-password/:token" />
									<Welcome path="/welcome/:token" />
									<Authenticated path="/*" />
								</Router>
							</AuthenticationContextProvider>
						</MaintenanceContextProvider>
					</Suspense>
				</GlobalErrorBoundary>
			</AlertContextProvider>
		</div>
	)
}

export default App
