import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"

import App from "./App"
import * as serviceWorker from "./serviceWorker"

// reach-ui imports
import "@reach/dialog/styles.css"

// i18n
import "./i18n"

// Styles
import "./tailwind.css"
import "./index.css"

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		release: `hyla-center-frontend@${process.env.REACT_APP_RELEASE_VERSION}`,
		environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
	})
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
