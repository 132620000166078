import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import ChainedBackend from "i18next-chained-backend"
import HttpBackend from "i18next-http-backend"
import resourcesToBackend from "i18next-resources-to-backend"
import { Settings } from "luxon"
import moment from "moment"

import { availableLanguages } from "./helpers/availableLanguages"

const supportedLangs = Object.keys(availableLanguages)

Settings.defaultLocale = "en"
moment.locale("en")
;(async function setMomentLocale() {
	const lngs = navigator.language?.split("-")
	const lng = lngs[0]
	const region = lngs[1] ?? undefined

	const momentLocale =
		lng.toLowerCase() === region?.toLowerCase() ? lng : `${lng}-${region}`

	// moment is only required until our datepicker (react-date)
	// updates to Ecma Temporal.
	if (momentLocale !== "en") {
		try {
			await import(
				/*
					webpackChunkName: "momentLocale"
				 */
				`moment/locale/${momentLocale}.js`
			)

			moment.locale(momentLocale)
		} catch (e) {}
	} else {
		moment.locale(momentLocale)
	}
})()

async function onChangeLocale(lng: string | undefined) {
	if (!lng) {
		lng = navigator.language?.split("-")[0]

		if (!lng) {
			return
		}
	}

	if (!supportedLangs.includes(lng)) {
		return
	}

	Settings.defaultLocale = lng
}

i18n.use(ChainedBackend)
	.use(initReactI18next)
	.init({
		fallbackLng: "en",
		debug:
			process.env.NODE_ENV === "development" &&
			process.env.REACT_APP_DEBUG_I18N === "true",

		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},

		backend: {
			backends: [
				resourcesToBackend((lng, ns, clb) => {
					import(`./locales/${lng}/${ns}.json`)
						.then((resources) => clb(null, resources))
						.catch((err) => clb(err, undefined))
				}),
				HttpBackend,
			],
			backendOptions: [
				{
					loadPath:
						"https://translate.hyla-center.com/parts/get/1/{{lng}}/{{ns}}.json",
				},
			],
		},

		defaultNS: "no-namespace",

		supportedLngs: supportedLangs,

		lng: "en",
	})
	.then(async () => {
		i18n.on("languageChanged", onChangeLocale)

		return onChangeLocale(i18n.language ?? undefined)
	})

export default i18n
