import React from "react"

import classNames from "../../helpers/classNames"

import Styles from "./Input.module.css"

const Annotation: React.FC<{
	appearance?: "warning"
}> = ({ children, appearance }) => {
	return (
		<p
			className={classNames({
				[Styles.annotation]: true,
				[Styles.annotationWarning]: appearance === "warning",
			})}
		>
			{children}
		</p>
	)
}

export default Annotation
