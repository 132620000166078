import React from "react"

import Styles from "./CircularLoadingIndicator.module.css"

const CircularLoadingIndicator = React.memo<
	| {
			value: number
			max: number
			intermediate?: boolean
	  }
	| {
			value?: number
			max: number
			intermediate: true
	  }
>(({ value = 0, max, intermediate }) => {
	const val = intermediate ? 0 : value
	return (
		<div
			className={Styles.radialProgress}
			data-progress={Math.round((val * 100) / max)}
		>
			<div className={Styles.bg} />
			{intermediate && <div className={Styles.intermediate} />}
			<div className={`${Styles.mask} ${Styles.full}`}>
				<div className={Styles.fill} />
			</div>
			<div className={Styles.mask}>
				<div className={Styles.fill} />
				<div className={`${Styles.fill} ${Styles.fix}`} />
			</div>
			<div className={Styles.inset} />
		</div>
	)
})

export default CircularLoadingIndicator
