import React from "react"
import { useTranslation } from "react-i18next"
import { DateTime } from "luxon"

import { LoginViewWrap } from "../pages/Login/Login"

type MaintenanceNotificationProps = {
	expectedDate?: DateTime
}
const MaintenanceNotification: React.FC<MaintenanceNotificationProps> = ({
	expectedDate,
}) => {
	const [t] = useTranslation("general")

	return (
		<LoginViewWrap>
			<h1>{t("maintenance.title", "Undergoing Maintenance")}</h1>
			<p>
				{t(
					"maintenance.description.noEstimate",
					"HYLA Center is currently undergoing maintenance."
				)}
			</p>
			{expectedDate && (
				<p>
					{t(
						"maintenance.description.estimate",
						"We expect the system to be available again at {{ date }}",
						{
							date: expectedDate
								.toLocal()
								.toLocaleString(DateTime.DATETIME_FULL),
						}
					)}
				</p>
			)}
		</LoginViewWrap>
	)
}

export default MaintenanceNotification
