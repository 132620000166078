import React from "react"
import { ReactComponent as Logo } from "assets/svg/logo.svg"

import LoadingIndicator from "../LoadingIndicator/LoadingIndicator"

import Styles from "./LoadingScreen.module.css"

type LoadingScreenProps = {}
const LoadingScreen: React.FC<LoadingScreenProps> = () => {
	return (
		<div className={Styles.wrap}>
			<div className={Styles.logoWrap}>
				<Logo className={Styles.logo} />
			</div>
			<div className={Styles.indicator}>
				<LoadingIndicator />
			</div>
		</div>
	)
}

export default LoadingScreen
