import React from "react"

import classNames from "../../helpers/classNames"

import Styles from "./LoadingIndicator.module.css"

type LoadingIndicatorProps = {
	active?: boolean
}
const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
	active = true,
}) => {
	return (
		<div
			className={classNames({
				[Styles.indicator]: true,
				[Styles.active]: active,
			})}
		/>
	)
}

export default LoadingIndicator

export const MediumLoadingIndicator: React.FC<
	LoadingIndicatorProps & {
		className?: string
	}
> = ({ className = "", children, ...props }) => {
	return (
		<div className="text-center">
			<div className={`h-8 w-8 m-auto ${className}`}>
				<LoadingIndicator {...props} />
			</div>
			{children && <p className="mt-4">{children}</p>}
		</div>
	)
}
