import React, {
	ComponentProps,
	createContext,
	PropsWithChildren,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react"
import { ObjectSchema, ValidationError } from "yup"
import { Form } from "@unform/web"
import { SubmitHandler, FormHandles } from "@unform/core"
import { useTranslation, Trans } from "react-i18next"
import { ApolloError } from "@apollo/client"
import { captureException } from "@sentry/react"

import Button from "../Buttons/Button"
import LoadingStateButton from "../Buttons/LoadingStateButton"

import Validation from "./Validation"

const ValidatedFormContext = createContext<{
	loading: boolean
	error: boolean
}>({
	loading: false,
	error: false,
})

export const SubmitFormButton: React.FC<ComponentProps<typeof Button>> = ({
	children,
	type = "submit",
	...props
}) => {
	const { loading, error } = useContext(ValidatedFormContext)

	return (
		<LoadingStateButton
			loading={loading}
			error={error}
			type={type}
			{...props}
		>
			{children}
		</LoadingStateButton>
	)
}

function ValidatedForm<T extends object>(
	props: PropsWithChildren<
		ComponentProps<typeof Form> & {
			validation: ObjectSchema<T>
			onSubmit: SubmitHandler<T>
		}
	>
): ReactElement | null {
	const [t] = useTranslation("general")

	const formRef = useRef<FormHandles>(null)
	const { children, validation, onSubmit, ...rest } = props

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [errorHints, setErrorHints] = useState<string[]>()
	const [isValidationError, setIsValidationError] = useState<boolean>()

	const mounted = useRef(false)

	useEffect(() => {
		mounted.current = true

		return () => {
			mounted.current = false
		}
	}, [])

	const onValidateSubmit = useCallback(
		async (data, helpers, event) => {
			if (event) {
				event.stopPropagation()
			}

			const form = formRef.current
			if (!form) {
				return
			}

			// reset validation
			form.setErrors({})

			setLoading(true)
			setError(false)
			setErrorHints(undefined)
			setIsValidationError(undefined)
			try {
				const validated = await validation.validate(data, {
					abortEarly: false,
					stripUnknown: true,
				})

				// pass only validated data to make sure the user
				// gets exactly what it specified
				await onSubmit(validated, helpers, event)
			} catch (e) {
				if (mounted.current) {
					setLoading(false)
					setError(true)
					setIsValidationError(e instanceof ValidationError)
				}

				if (e instanceof ApolloError) {
					setErrorHints(
						e.graphQLErrors
							.filter((error) => {
								return error.extensions?.category === "Validate"
							})
							.map((error) => {
								return error.message
							})
					)
				}

				if (process.env.NODE_ENV === "development") {
					console.log(e, data)
				}

				if (e instanceof ValidationError) {
					form.setErrors(
						Object.fromEntries(
							e.inner.map((error) => [error.path, error.message])
						)
					)

					return
				}

				captureException(e)
			}

			if (mounted.current) {
				setLoading(false)
			}
		},
		[validation, onSubmit]
	)

	const ctx = useMemo(() => {
		return {
			loading,
			error,
		}
	}, [loading, error])

	return (
		<Form {...rest} ref={formRef} onSubmit={onValidateSubmit}>
			<ValidatedFormContext.Provider value={ctx}>
				{error && (
					<Validation>
						{isValidationError &&
							t(
								"validatedForm.validationError",
								"Your data did not pass validation and was not sent to the server. Please check your entries."
							)}
						{!isValidationError && (
							<>
								<Trans
									t={t}
									i18nKey="validatedForm.serverError"
								>
									<p>
										Your data could not be processed by the
										server. Please check your entries.
										<br />
										If this problem keeps appearing, please
										make a screenshot of this page and
										contact support.
										<br />
									</p>

									{!!errorHints && errorHints.length > 0 && (
										<>
											<p>
												The Server transmitted the
												following error messages:
											</p>
										</>
									)}
								</Trans>
								{!!errorHints &&
									errorHints.length > 0 &&
									errorHints.map((error) => <p>{error}</p>)}
							</>
						)}
					</Validation>
				)}
				{children}
			</ValidatedFormContext.Provider>
		</Form>
	)
}

export default ValidatedForm
