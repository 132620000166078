import React, { Component, ReactNode } from "react"
import * as Sentry from "@sentry/react"

interface StateType {
	hasError: boolean
}
export default abstract class ErrorBoundary extends Component<{}, StateType> {
	constructor(props: any) {
		super(props)

		this.state = {
			hasError: false,
		}
	}

	protected abstract captureError: boolean

	abstract canHandleError(error: Error): boolean
	abstract renderOnError(): ReactNode

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		if (process.env.NODE_ENV === "development") {
			console.log(error, errorInfo)
		}

		if (!this.canHandleError(error)) {
			return
		}

		if (this.captureError) {
			Sentry.captureException(error, {
				extra: {
					errorInfo,
				},
			})
		}
	}

	public render(): ReactNode {
		const { hasError } = this.state

		if (hasError) {
			return this.renderOnError()
		}

		return this.props.children
	}
}
