import React, { ReactNode } from "react"

import ErrorBoundary from "./ErrorBoundary"

export class GlobalErrorBoundary extends ErrorBoundary {
	protected captureError = true

	public canHandleError(error: Error): boolean {
		return true
	}

	static getDerivedStateFromError(error: Error) {
		return {
			hasError: true,
		}
	}

	public renderOnError(): ReactNode {
		return (
			<>
				<h1>Could not start application</h1>
				<p>
					There was a critical error while starting the application.
					<br />
					The issues has been reported to the technical staff and will
					be resolved as soon as possible.
				</p>
			</>
		)
	}
}
