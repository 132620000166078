export type ClassName =
	| string
	| undefined
	| null
	| false
	| Record<string, boolean>
const classNames = (...args: ClassName[]) => {
	const classes = []

	for (const item of args) {
		if (!item) {
			continue
		}

		if (typeof item === "string") {
			classes.push(item)

			continue
		}

		for (const key in item) {
			if (key.length === 0 || !item[key]) {
				continue
			}

			classes.push(key)
		}
	}

	return classes.join(" ")
}

export default classNames
