import { DateTime } from "luxon"
import { FieldReadFunction } from "@apollo/client"

export const readDateTime: FieldReadFunction = (
	iso: string | undefined,
	...args
) => {
	if (!iso) {
		return iso
	}

	return DateTime.fromISO(iso).toLocal()
}

export const readDateTimeUTC: FieldReadFunction = (
	iso: string | undefined,
	...args
) => {
	if (!iso) {
		return iso
	}

	return DateTime.fromISO(iso).toUTC()
}

export const readDateTimeArray = (isos: string[] | undefined) => {
	if (!isos) {
		return isos
	}

	return isos.map((iso) => DateTime.fromISO(iso).toLocal())
}

export const readOptionalDateTime = (iso: string | undefined | null) => {
	if (iso === null || iso === undefined) {
		return iso
	}

	return DateTime.fromISO(iso).toLocal()
}
